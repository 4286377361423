<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-btn icon>
        <v-icon>mdi-list-status</v-icon>
      </v-btn>
      <v-toolbar-title>Log</v-toolbar-title>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        single-line
        hide-details
        clearable
        id="search"
      ></v-text-field>
      <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tabs-slider color="error"></v-tabs-slider>
          <v-tab :to="{ name: 'LogError' }"> Errors </v-tab>
          <v-tab :to="{ name: 'LogPerson', params: { id: personId } }">
            Person
          </v-tab>
          <v-tab :to="{ name: 'LogResource' }"> Resource </v-tab>
          <v-tab :to="{ name: 'LogResourceUsage' }"> Statistik </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <router-view :search="search" v-bind:personId.sync="personId" />
  </v-container>
</template>

<script>
export default {
  name: 'Log',
  data() {
    return {
      search: '',
      personId: 0,
    };
  },
};
</script>
